<template>
  <div class="booking-detail-action-button">
    <!-- Pending -->
    <div v-if="status === projectBookingStatus.PENDING">
      <span v-if="!canApprove" class="d-flex justify-content-end">
        <Banner
          class="bg-grey-100 fg-grey-700 px-3 py-2 mb-2"
          :dismissable="false"
        >
          Pending buyer to complete booking.
        </Banner>
      </span>
      <button
        v-if="canManage"
        class="btn main mr-1"
        :disabled="!canApprove"
        @click="$emit('openApproveModal')"
      >
        Approve
      </button>

      <dropdown-button v-if="canManage" buttonClass="bordered main">
        <template #buttonContent>
          <i class="fas fa-ellipsis-v"></i>
        </template>
        <div class="card text-left">
          <fd-list-item
            class="cursor-pointer unselectable"
            @click="$emit('openRejectModal')"
          >
            <i class="fas fa-hand-paper mr-1"></i>
            Reject Booking
          </fd-list-item>
          <fd-list-item
            class="cursor-pointer unselectable"
            @click="$emit('openCancelModal')"
          >
            <i class="fas fa-times mr-1"></i>
            Cancel Booking
          </fd-list-item>
        </div>
      </dropdown-button>
    </div>
    <!-- Rejected -->
    <div v-else-if="status === projectBookingStatus.REJECTED">
      <button class="col-12 btn main" @click="$emit('viewRejectedReason')">
        View Rejected Reason
      </button>
      <button
        class="btn danger bordered ml-1"
        title="Cancel"
        @click="$emit('openCancelModal')"
      >
        <i class="fas fa-times mr-1"></i>
        Cancel
      </button>
    </div>
    <!-- Cancelled -->
  </div>
</template>

<script>
import { projectBookingStatus } from "@/enums";

export default {
  name: "BookingDetailActionButtons",
  components: {
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    Banner: () => import("@/components/GlobalComponents/Banner"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    },
    canManage: {
      type: Boolean,
      default: true
    },
    canApprove: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { projectBookingStatus };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
